// in the list of services below, the items with a "*" are required services.
// *calculateCart
// findLocation
// geoCurrencies
// getOrderBump
// *getProducts.
// *getSiteSettings
// *getTokenExIframe
// getTrackingPixels
// *submit

// required
const SITE_SETTINGS = `
	query siteSettingsQuery($vendorId: String!) {
		siteSettings(filter: "order", vendorId: $vendorId) {
			vendorId
			couponDisplayed
			flexibleRefundEnabled
			allowPhone
			allowEditQuantity
			cartAbandonment
			activated
			adminRestrictFlexibleRefund
			adminRefundDaysLimit
			vendorBrand,
			orderConfirmationSmsEnabled,
		}
	}
`;
// required
const TOKEN_EX_IFRAME = `
	query tokenExQuery($vendorId: String) {
		tokenExIframe (vendorId: $vendorId) {
			config
		}
	}
`;

// required - shippable can be for the product and a recurring product and they can be different.  priceModel.shippable is for recurring products.
const PRODUCTS = `
	query productsQuery($vendorId: String!, $skus: [String!]!) {
		products(filter: "order", vendorId: $vendorId, skus: $skus) {
			id
			type
			maxQuantity
			image
			sku
			title
			delayedDelivery
			refundDaysLimit
			isTestPurchaseOnly
			shippable
			phoneRequired
			description
			priceModel {
				frequency
				maxPayments
				initialOffsetValue
				type
				refundDaysLimit
				shippable
			}
		}
	}
`;

const ORDER_BUMP = `
	query orderBumpQuery($vendorId: String!, $skus: [String!]!) {
		orderBump(filter: "order", vendorId: $vendorId, skus: $skus) {
			orderBumpProducts {
				product {
					id
					type
					maxQuantity
					image
					sku
					title
					delayedDelivery
					isTestPurchaseOnly
					shippable
					phoneRequired
					description
					priceModel {
						frequency
						maxPayments
						initialOffsetValue
						type
						refundDaysLimit
						shippable
					}
				}
				sequence
				textType
				text
			}
		}
	}
`;

// required
const CALCULATE_CART = `
	query calculateCartQuery(
		$address: AddressOrderForm
		$lineItems: [CalculateOrderLineItem!]!
		$vendorId: String!
		$currencyId: String!
		$couponCode: String
		$availableItems: [CalculateOrderLineItem!]
		$urlParams: String
	) {
		calculateCart(
			address: $address
			lineItems: $lineItems
			vendorId: $vendorId
			currencyId: $currencyId
			couponCode: $couponCode
			availableItems: $availableItems
			urlParams: $urlParams
		) {
			lineItems {
				productId
				quantity
				unitPrice
				amount
				recurringUnitPrice
				recurringAmount
				discountAmount
			}
			duplicate
			shippingAmount
			subtotal
			discountTotal
			discountRate
			softDescriptor
			total
			tax
			availableItems {
				productId
				quantity
				unitPrice
				amount
				recurringUnitPrice
				recurringAmount
				discountAmount
			}
		}
	}
`;

const GEO_CURRENCIES = `
	query geoCurrenciesQuery($vendorId: String!, $skus: [String!]!, $affiliate: String, $urlParams: String) {
		geoCurrencies(filter: "order", vendorId: $vendorId, skus: $skus, affiliate: $affiliate, urlParams: $urlParams) {
			regulation
			forceShipping
			phoneNumberRequired
			countries {
				countryCode
				default
				regulations {
					key
					value
				}
				requireLocation
				autocomplete
				currencies {
					key
					value{
						code
						accepted
						default
					}
				}
			}
		}
	}
`;

const TRACKING_PIXELS = `
	query trackingPixelsQuery(
		$eventName: String!
		$vendorId: String
		$sessionToken: String
		$urlParams: String
		$affiliate: String
		$affiliateTrackingCode: String
	) {
		trackingPixels(
			filter: "order"
			vendorId: $vendorId
			eventName: $eventName
			sessionToken: $sessionToken
			urlParams: $urlParams
			affiliate: $affiliate
			affiliateTrackingCode: $affiliateTrackingCode
		) {
			service {
				name
				srcUrl
				... on CustomPixelTrackingService {
					url
				}
				... on FacebookPixelTrackingPixelService {
					facebookPixelId
				}
				... on GoogleAdWordsTrackingPixelService {
					googleAdWordsId
					pageViewLabel
					purchaseLabel
				}
				... on GoogleAnalyticsTrackingPixelService {
					domains
					googleAnalyticsId
				}
				... on YahooSearchMarketingTrackingPixelService {
					accountId
				}
			}

			vendorId
			perspective
		}
	}
`;

const AFFILIATE = `
	query affiliateQuery($vendorId: String!, $urlParams: String! ) {
		affiliate(filter: "order", vendorId: $vendorId, urlParams: $urlParams ) {
			trackingCode
			name
			reason
			stamp
		}
	}
`;

const UPSELL_FLOW_RECEIPTS = `
	query upsellFlowReceiptsQuery($upsellFlowSessionId: String!) {
		upsellFlowReceipts(upsellFlowSessionId: $upsellFlowSessionId) {
			vendorId
			receipt
			currencyId
			paymentMethod
			tokenProvider
			fullName
			cardHolderName
			email
			editUrl
			last4cc
			softDescriptor
			timestamp
			sessionToken
			billingAddress{
				countryCode
				zip
				city
				state
				address1
				address2
			}
			shippingAddress {
				countryCode
				zip
				city
				state
				address1
				address2
			}

			purchasedProducts {
				link
				redirect
				productId
			}
			calculatedCart {
				lineItems {
					productId
					quantity
					unitPrice
					amount
					recurringUnitPrice
					recurringAmount
					discountAmount
				}
				shippingAmount
				subtotal
				discountTotal
				discountRate
				total
				tax
				availableItems {
					productId
					quantity
					unitPrice
					amount
					recurringUnitPrice
					recurringAmount
					discountAmount
				}
			}
		}
	}
`;

const TEMPLATE = `
query templateQuery($alias: String!, $vendorId: String!, $preview: Boolean) {
	formTemplate(identifier: $alias, vendorId: $vendorId, identifierType: "alias", preview: $preview ) {
		layout
		name
		alias
		css
		countdownTimer {
			actionType
			properties {
				propertyType
				propertyStatus
				propertyValue
				propertyLabel
			}
			timer {
				type
				... on DynamicTimer {
					seconds
				}
				... on StaticTimer {
					timestamp
				}
			}
			payLink	
		}
		style {
			attributes
		}
		zones {
			id
			style {
				attributes
			}
			widgets {
				componentName
				visibility
				style {
					attributes
				}
				... on OrderFormTextWidget {
					text
				}
				... on OrderFormImageWidget {
					image {
						imageKey
						imageUrl
						imageTitle
						imageAlt
					}
				}
				... on OrderFormTextImageWidget {
					text
					image {
						imageKey
						imageUrl
						imageTitle
						imageAlt
					}
				}
				... on OrderFormCountdownTimerWidget {
					topText
					bottomText
					expirationText
					id
				}
			}
		}
	}
}
`;

// TODO: Add expirationText (or wtv the BE ends up calling it) with top/bottomText above

const EXIT_OFFER = `
	query exitOfferQuery($alias: String!, $vendorId: String!, $preview: Boolean) {
		exitOfferTemplate(identifier: $alias, vendorId: $vendorId, identifierType: "alias", preview: $preview) {
			alias
			name
			actionType
			payLink
			css
			properties {
				propertyType
				propertyValue
				propertyLabel
			}
			style {
				attributes
			}
			zones {
				id
				style {
					attributes
				}
				widgets {
					componentName
					visibility
					id
					style {
						attributes
					}
					... on OrderFormTextWidget {
						text
					}
					... on OrderFormImageWidget {
						image {
							imageKey
							imageTitle
							imageUrl
							imageAlt
						}
					}
					... on OrderFormTextImageWidget {
						text
						image {
							imageKey
							imageUrl
							imageTitle
							imageAlt
						}
					}
				}
			}
		}
	}
`;

const REFRESH_AUTH = `
	query refreshAuth($refreshToken: String!) {
		refreshAuthentication(refreshToken: $refreshToken) {
			accessToken
			refreshToken
		}
	}
`;

const IS_FEATURE_ENABLED_QUERY = `
  query isFeatureEnabledQuery($featureName: String!, $username: String) {
	isFeatureEnabled(featureName: $featureName, username: $username)
  }
`;

const VERIFY_ADDRESS_QUERY = `
	query verifyAddressQuery($addressVerifyRequest: AddressVerifyRequest!) {
		addressVerify(addressVerifyRequest: $addressVerifyRequest) {
			result
			reason
			editedFields
			verifiedSuggestion {
				address1
				address2
				city
				state
				zip
				countryCode
				entries
			}
		}
	}
`;

const ADDRESS_SEARCH_QUERY = `
	query addressSearch($addressSearchRequest: AddressSearchRequest!) {
		addressSearch(addressSearchRequest: $addressSearchRequest) {
			address1
			address2
			city
			state
			zip
			countryCode
			entries
			addressId
			addressText
		}
	}
`;

export {
	SITE_SETTINGS,
	TOKEN_EX_IFRAME,
	PRODUCTS,
	ORDER_BUMP,
	CALCULATE_CART,
	GEO_CURRENCIES,
	TRACKING_PIXELS,
	AFFILIATE,
	UPSELL_FLOW_RECEIPTS,
	TEMPLATE,
	REFRESH_AUTH,
	EXIT_OFFER,
	IS_FEATURE_ENABLED_QUERY,
	ADDRESS_SEARCH_QUERY,
	VERIFY_ADDRESS_QUERY,
};
