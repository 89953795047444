import { fetchUrl, fetchWithRetry, parseAttributes } from './fetchHelpers';
import { TEMPLATE } from '@/graphql/queries';
import { CountdownTimerActionType } from '@/utils/enums';
import { isDevEnv, isPreview } from '@/utils/helpers';

const getTemplateQuery = async ({ urlVars, fetchHeaders }) => {
	try {
		if (!urlVars || !urlVars.template) {
			return {};
		}
		const body = await fetchWithRetry('TEMPLATE', fetchUrl, {
			method: 'POST',
			headers: fetchHeaders,
			body: JSON.stringify({
				query: TEMPLATE,
				variables: {
					alias: urlVars.template,
					vendorId: urlVars.vvvv,
					// this tells the BE to change out the image id for the image URL when the env is not prod.
					preview: isPreview || isDevEnv,
				},
				queryName: 'templateQuery',
			}),
		});
		if (body.error) {
			throw body.error;
		}
		const { data } = body;
		const state = {};
		const rawTemplate = data?.formTemplate;
		if (rawTemplate) {
			const template = parseAttributes(rawTemplate);
			const nullImagesRemovedZones = template.zones.map((zone) => {
				if (!zone.widgets) {
					return zone;
				}

				const updatedWidgets = zone.widgets.filter((widget) => {
					const { componentName, image, text } = widget;

					if (componentName === 'TEXT_IMAGE' || componentName === 'IMAGE') {
						if (!image) {
							return false;
						}
					}

					if (componentName === 'TEXT' || componentName === 'TEXT_IMAGE') {
						if (!text) {
							return false;
						}
					}

					return true;
				});
				return { ...zone, widgets: updatedWidgets };
			});

			// ADD CUSTOM CSS STYLE TAG TO THE END OF THE HEAD TAG
			if (template.css) {
				const styleElement = document.createElement('style');
				styleElement.setAttribute('id', 'data-custom-styles');
				styleElement.appendChild(document.createTextNode(template.css));
				document.head.appendChild(styleElement);
			}

			if (template.countdownTimer && template.countdownTimer.timer.type === 'STATIC') {
				const { timer, actionType, payLink } = template.countdownTimer;
				const countdownTimerHasExpired = Date.now() >= parseInt(timer.timestamp, 10);
				state.countdownTimerHasExpired = countdownTimerHasExpired;
				if (
					actionType === CountdownTimerActionType.PAYLINK &&
					payLink &&
					countdownTimerHasExpired
				) {
					window.location.href = payLink;
					return new Promise((_res, rej) => {
						window.setTimeout(
							() =>
								rej({
									state: { hasTemplateQueryFailed: true },
									errors: { FATAL: 'Expired timer' },
								}),
							10000,
						);
					});
				}
			}

			template.zones = nullImagesRemovedZones;
			state.template = template;
			state.hasTemplateQueryData = true;
		} else {
			throw 'Invalid template';
		}
		return {
			graphql: { TEMPLATE: data },
			state,
		};
	} catch (err) {
		return Promise.reject({
			state: { hasTemplateQueryFailed: true },
			errors: { TEMPLATE: err },
		});
	}
};

export default getTemplateQuery;
