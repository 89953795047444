import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import { isEqual } from 'lodash';
import { IMask } from 'react-imask';
import { v4 as uuidv4 } from 'uuid';

import { logSplunkRumError } from './helpers';
import { getMask, maskDefinitions } from './textMasking';
import { PaymentMethods } from '@/utils/enums';

const getTwentyFourHoursFromNow = () => {
	return dayjs().add(24, 'hour');
};

export const resetPazeData = (setPazeData) => {
	return setPazeData((prev) => {
		return {
			...prev,
			checkoutResponse: null,
			existingCompleteResponse: null,
			existingCompleteResponseExp: null,
			uuid: uuidv4(),
		};
	});
};

const isStandardErrorReasonCode = (error) => {
	return [
		'UNABLE_TO_CONNECT',
		'SERVER_ERROR',
		'SERVICE_ERROR',
		'AUTH_ERROR',
		'NOT_FOUND',
	].includes(error.reason);
};

/**
 * purpose - initializes a configuration for the paze checkout
 * @param {string} usersSOFEmail - user's email
 * @param {object} pazeData - paze data that includes supportedCountries and acceptedCCs etc
 * @returns {object} - paze checkout configuration
 */
export const getPazeCheckoutConfig = (usersSOFEmail, pazeData, isShippable, transactionValue) => {
	const { supportedCountries, acceptedCCs, uuid, checkoutResponse } = pazeData;
	// TODO: User should be able to indicate which action they are taking so appropriate code can be used

	// const actionCode = checkoutResponse
	// 	? isShippable
	// 		? 'CHANGE_SHIPPING_ADDRESS'
	// 		: 'CHANGE_CARD'
	// 	: 'START_FLOW';
	return {
		email: usersSOFEmail,
		sessionId: uuid,
		actionCode: checkoutResponse ? 'CHANGE_CARD' : 'START_FLOW',
		shippingPreference: isShippable ? 'ALL' : 'NONE',
		billingPreference: 'ALL',
		/* 
			Paze throws an "INVALID_PARAMETER" error if the acceptedShippingCountries is not set to "US"
			 see Paze FE Integration Doc in Confluence
		 */
		acceptedShippingCountries: supportedCountries, // ["US"] only right now
		acceptedPaymentCardNetworks: acceptedCCs,
		...(transactionValue.transactionAmount && { transactionValue }),
	};
};

/**
 * purpose - checks if the user can checkout with Paze
 * @param {string} emailAddress - user's email
 * @param {function} setPazeData - setter function to set paze data
 * @param {string} paymentMethod - currently selected payment method
 * @param {function} setPaymentMethod - setter function to set payment method
 * @param {function} setFormServerErrorInfo - setter function to set form server error info
 * @returns {void} - sets Paze data consumerPresent bool to the store based on the canCheckout response
 */
export const pazeCanCheckout = async (
	emailAddress,
	setPazeData,
	paymentMethod,
	setPaymentMethod,
	setFormServerErrorInfo,
) => {
	if (!window.DIGITAL_WALLET_SDK) {
		logSplunkRumError('DIGITAL_WALLET_SDK not found');
		return;
	}

	try {
		const canCheckoutWithPaze = await window.DIGITAL_WALLET_SDK.canCheckout({ emailAddress });
		if (canCheckoutWithPaze) {
			if (
				canCheckoutWithPaze.consumerPresent === false &&
				paymentMethod === PaymentMethods.PAZE
			) {
				setFormServerErrorInfo(null);
				setPaymentMethod(PaymentMethods.CREDIT);
			}

			setPazeData((prev) => {
				return {
					...prev,
					consumerPresent: canCheckoutWithPaze.consumerPresent,
				};
			});
		}
	} catch (error) {
		console.error('error occurred with Paze canCheckout', JSON.stringify(error));
	}
};

const maskAddressZip = async (accessor, address) => {
	try {
		const { IMask } = await import('react-imask');
		const mask = IMask.createMask({
			mask: getMask(`${accessor}.zip`, address.zip, address.countryCode),
			placeholderChar: '\u2000',
			definitions: maskDefinitions,
		});
		mask.resolve(address.zip);
		return {
			...address,
			zip: mask.value,
		};
	} catch (e) {
		return { ...address };
	}
};

/** TODO: mike update all js docs based on recent changes
 * purpose - opens Paze UI to gather the user's CC and shipping information
 * @param {object} pazeData - paze data that includes supportedCountries and acceptedCCs etc
 * @param {function} setPazeData - setter function to set paze data
 * @param {function} handlePaymentMethodChange - function to handle payment method change
 * @param {function} setErrorData - setter function to set error data
 * @param {string} email - user's email
 * @param {function} setIsCartUpdating - function that shows spinner on cart
 * @param {function} setCalculateCartAddressVars - function to set calculate cart address vars so we can trigger a calculate cart
 * @returns {void} - sets Paze data to the store based on the checkout response
 */
export const pazeCheckout = async (
	pazeData,
	setPazeData,
	handlePaymentMethodChange,
	setErrorData,
	email,
	setIsCartUpdating,
	calculateCartAddressVars,
	setCalculateCartAddressVars,
	orderTotals,
	isShippable,
	selectedCurrency,
	mergeFormData,
	validateForm,
) => {
	try {
		const subtotal = orderTotals?.[0]?.subtotal;
		const transactionValue = {
			transactionCurrencyCode: selectedCurrency,
			transactionAmount: subtotal,
		};
		const pazeCheckoutConfig = getPazeCheckoutConfig(
			email,
			pazeData,
			isShippable,
			transactionValue,
		);
		const checkoutResponse = await window.DIGITAL_WALLET_SDK.checkout(pazeCheckoutConfig);

		if (checkoutResponse?.result === 'COMPLETE') {
			const decodedResponse = jwtDecode(checkoutResponse.checkoutResponse);
			const {
				shippingAddress: shippingAddressFromPaze,
				maskedCard: { billingAddress: billingAddressFromPaze },
			} = decodedResponse;
			setIsCartUpdating(true);
			// This accessor is defined for future-proofing - the shipping key in formStore is UNUSED currently,
			// until two addresses functionality is added.
			const billingAddress = await maskAddressZip('billing', billingAddressFromPaze);
			const shippingAddress = await maskAddressZip('shipping', shippingAddressFromPaze);
			const taxableAddress = isShippable ? shippingAddress : billingAddress;
			const newVars = {
				countryCode: taxableAddress.countryCode,
				zip: taxableAddress.zip,
				city: taxableAddress.city,
				state: taxableAddress.state,
				address1: taxableAddress.line1,
			};
			if (isEqual(calculateCartAddressVars, newVars)) {
				// No need to recalculate tax - set back to not updating
				setIsCartUpdating(false);
			} else {
				setCalculateCartAddressVars(newVars);
			}
			// TODO: conditionally select
			mergeFormData(
				{
					billing: {
						// When we begin handling two addresses, we will need to merge to billing and/or shipping
						fullName: isShippable
							? shippingAddress.deliveryContactDetails?.contactFullName ||
								shippingAddress.name ||
								''
							: taxableAddress.name,
						address1: taxableAddress.line1 || '',
						address2: taxableAddress.line2 || '',
						zip: taxableAddress.zip || '',
						city: taxableAddress.city || '',
						state: taxableAddress.state || '',
						...(taxableAddress.countryCode && {
							countryCode: taxableAddress.countryCode,
						}),
					},
				},
				true,
			);
			validateForm();
			// TODO: Mike handle error for calculate cart and paze checkout
			setPazeData((prev) => ({
				...prev,
				checkoutResponse: decodedResponse,
			}));
		} else {
			/* 
			result is “INCOMPLETE”: Consumer exited the
			Checkout, failed the authentication
			check, or did not complete Checkout
			before their user session expired.

			Because we don't know what the user did, if we have the
			users cc details we will leave the actionCode as 'CHANGE_CARD'
			and not attempt to 'restart' the Paze flow until Paze provides
			a more informed result.
			*/

			if (!pazeData.checkoutResponse) {
				handlePaymentMethodChange(PaymentMethods.CREDIT);
			}
		}
	} catch (error) {
		console.error(JSON.stringify(error));
		if (isStandardErrorReasonCode(error)) {
			setErrorData({
				message: 'paze-checkout-error',
				messageType: 'cart',
			});
		}
		if (error.reason === 'CLIENT_DATA_INVALID') {
			// The actionCode is 'CHANGE_CARD' (digital product) and the user only has one CC in their paze wallet.
			// This will not occur for 'CHANGE_SHIPPING_ADDRESS' if we are setting shippingPreference to 'ALL' if physical product.
			setErrorData({
				message: 'paze-checkout-client-data-invalid',
				messageType: 'cart',
			});
		} else {
			setErrorData({
				message: 'paze-checkout-error',
				messageType: 'cart',
			});
			handlePaymentMethodChange(PaymentMethods.CREDIT);
		}
	}
};

/**
 * purpose - completes the Paze transaction
 * @param {object} orderTotals - order totals
 * @param {string} selectedCurrency - currently user selected currency
 * @param {function} submitDigitalPayment - function to submit digital payment
 * @param {object} submitVars - variables to submit
 * @param {object} pazeData - paze data that includes supportedCountries and acceptedCCs etc
 * @param {function} handleCreditResponse - function to parse response for receipt page
 * @param {function} setSubmitOverlayVisible - function to set submit overlay visible/not visible
 * @param {function} setErrorData - function to set error data
 * @returns {void} - parses the response for receipt page
 */
export const pazeSubmit = async (
	orderTotals,
	selectedCurrency,
	submitDigitalPayment,
	submitVars,
	pazeData,
	setPazeData,
	handleCreditResponse,
	setSubmitOverlayVisible,
	setErrorData,
	setPaymentMethod,
	isShippable,
	getFormData,
	calculateCartAddressVars,
) => {
	try {
		let completeResponseToSend = pazeData.existingCompleteResponse;
		const currentTime = dayjs();
		const existingCompleteResponseHasExpired =
			pazeData.existingCompleteResponseExp &&
			pazeData.existingCompleteResponseExp.isBefore(currentTime);

		// if completeResponse is no longer valid/expired, reset because the checkout response is likely stale
		if (existingCompleteResponseHasExpired) {
			setPaymentMethod(PaymentMethods.CREDIT);
			resetPazeData(setPazeData);
			setSubmitOverlayVisible(false);
			setErrorData({ message: 'paze-session-expired', messageType: 'cart' });
			console.warn('existing complete expiration met, resetting pazeData');
			return;
		}

		//  Only call complete if there is no completeResponse saved
		if (!pazeData.existingCompleteResponseExp) {
			const newCompleteResponse = await window.DIGITAL_WALLET_SDK.complete({
				sessionId: pazeData.uuid,
				transactionType: 'PURCHASE', // According to Chase we can use Purchase for one-time purchase or subscription
				transactionValue: {
					transactionCurrencyCode: selectedCurrency,
					transactionAmount: orderTotals[0].total,
				},
				transactionOptions: {
					billingPreference: 'NONE',
					payloadTypeIndicator: 'PAYMENT',
				},
			});

			// if complete is successful, save the response in state for 24 hours (for now).
			const newPazeData = setPazeData((prev) => {
				return {
					...prev,
					existingCompleteResponse: newCompleteResponse.completeResponse,
					existingCompleteResponseExp: getTwentyFourHoursFromNow(),
				};
			});
			completeResponseToSend = newPazeData.existingCompleteResponse;
		}
		if (!pazeData.checkoutResponse) {
			console.error('checkoutResponse not found in pazeData during Paze submit');
			setErrorData({ message: 'paze-checkout-error', messageType: 'cart' });
			setSubmitOverlayVisible(false);
			return;
		}

		const { maskedCard, consumer } = pazeData.checkoutResponse;
		const masked = IMask.createMask({
			mask: getMask(
				'billing.zip',
				maskedCard.billingAddress.zip,
				maskedCard.billingAddress.countryCode,
			),
			placeholderChar: '\u2000',
			definitions: maskDefinitions,
		});
		masked.resolve(maskedCard.billingAddress.zip);
		const billingZip = masked.value;
		// TODO: billing key should become shipping key when handling two addresses in form
		const {
			billing: { fullName: shippingName, ...shippingAddress },
		} = getFormData();
		// The below logic seems confusing - we are grabbing from the `billing` key above but refer to the data as `shipping` data,
		// this is because currently `shipping` is unused on the form until second address functionality is added.
		// For Paze, any data entered in the form is `shipping` info, and the `billing` info
		// (e.g. payment address and cardholder name) come from the card data in the decoded Paze response
		const shippingFindLocationActive =
			isShippable && ['CA', 'US'].includes(shippingAddress.countryCode);
		if (shippingFindLocationActive) {
			const keysToTest = ['zip', 'state', 'countryCode'];
			if (shippingAddress.countryCode === 'US') {
				keysToTest.push('city');
			}
			const shippingKeysMatch = keysToTest.every((field) => {
				return shippingAddress[field] === calculateCartAddressVars[field];
			});
			if (!shippingKeysMatch) {
				// Find location in progress - do not allow submit
				setSubmitOverlayVisible(false);
				return;
			}
		}
		const { data, error } = await submitDigitalPayment({
			variables: {
				pazeToken: {
					panLastFour: maskedCard.panLastFour,
					paymentCardType: maskedCard.paymentCardType,
					paymentCardBrand: maskedCard.paymentCardBrand,
					paymentCardNetwork: maskedCard.paymentCardNetwork,
					encryptedPayloadText: completeResponseToSend,
				},
				cardHolderName: consumer.firstName + ' ' + consumer.lastName,
				email: consumer.emailAddress,
				address: {
					countryCode: maskedCard.billingAddress.countryCode,
					zip: billingZip,
					city: maskedCard.billingAddress.city,
					state: maskedCard.billingAddress.state,
				},
				...(isShippable && {
					fullName: shippingName,
					shippingAddress,
				}),
				...submitVars,
				phone: submitVars.phone
					? submitVars.phone
					: '+' + consumer.mobileNumber.countryCode + consumer.mobileNumber.phoneNumber,
			},
		});

		if (error) {
			if (error.graphQLErrors?.[0]?.extensions?.status === 401) {
				// if 401 (expired session/declined CC) we reset pazeData
				setErrorData({ message: 'paze-declined-or-expired', messageType: 'cart' });
				setPaymentMethod(PaymentMethods.CREDIT);
				setSubmitOverlayVisible(false);
				resetPazeData(setPazeData);
				return;
			}

			if (error.graphQLErrors?.[0]?.message === 'Already Purchased') {
				setErrorData({ message: 'paze-duplicate-order', messageType: 'cart' });
				setSubmitOverlayVisible(false);
				return;
			}

			// Instead of resetting pazeData for all other errors here, we are doing it only if the user clicks "edit" (right before handlePazeEdit)
			setSubmitOverlayVisible(false);
			setErrorData({ message: 'paze-checkout-error', messageType: 'cart' });
			return;
		}

		if (data?.submitDigitalPayment?.receipt) {
			handleCreditResponse(data.submitDigitalPayment);
		}
	} catch (error) {
		setSubmitOverlayVisible(false);
		setErrorData({ message: 'paze-checkout-error', messageType: 'cart' });
		console.error('error sent to catch in pazeSubmit', JSON.stringify(error));
	}
};
