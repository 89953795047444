import { fetchUrl, fetchWithRetry } from './fetchHelpers';
import { TOKEN_EX_IFRAME } from '@/graphql/queries';

const getTokenExQuery =
	({ urlVars, fetchHeaders }) =>
	async () => {
		try {
			const body = await fetchWithRetry('TOKEN_EX_IFRAME', fetchUrl, {
				method: 'POST',
				headers: fetchHeaders,
				body: JSON.stringify({
					query: TOKEN_EX_IFRAME,
					variables: {
						vendorId: urlVars.vvvv,
					},
					queryName: 'tokenExQuery',
				}),
			});
			if (body.error) {
				throw body;
			}
			const { data } = body;
			const tokenExConfig = data?.tokenExIframe?.config;
			if (!tokenExConfig) {
				throw { error: 'Missing tokenex config' };
			}
			const state = {
				tokenExConfig,
			};
			return {
				graphql: { TOKEN_EX_IFRAME: data },
				state,
			};
		} catch (body) {
			return Promise.reject({ errors: { TOKEN_EX_IFRAME: body } });
		}
	};

export default getTokenExQuery;
