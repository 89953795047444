import { fetchUrl, fetchWithRetry } from './fetchHelpers';
import { IS_FEATURE_ENABLED_QUERY } from '@/graphql/queries';
import { FeatureFlags } from '@/utils/enums';
import { isDevEnv } from '@/utils/helpers';

// create an array from the values of the FeatureFlags object
const featureFlagsArr = Object.values(FeatureFlags);

const getFeatureFlagsQuery = async ({ urlVars, fetchHeaders }) => {
	try {
		if (!urlVars || !urlVars.vvvv || !featureFlagsArr.length) {
			return {};
		}
		const activeFeatureFlags = featureFlagsArr.reduce((a, key) => {
			a[key] = false;
			return a;
		}, {});
		const enabledFlags = {};
		const allFlags = {};
		const promises = [];
		const state = {};

		// use local storage to set feature flags in dev environment and return early
		if (isDevEnv) {
			featureFlagsArr.forEach((featureName) => {
				if (localStorage.getItem(featureName) === 'true') {
					enabledFlags[featureName] = true;
				}
			});
			state.featureFlags = { ...activeFeatureFlags, ...enabledFlags };
			return { state };
		}

		featureFlagsArr.forEach((featureName) => {
			const promise = fetchWithRetry('IS_FEATURE_ENABLED_QUERY', fetchUrl, {
				method: 'POST',
				headers: fetchHeaders,
				body: JSON.stringify({
					query: IS_FEATURE_ENABLED_QUERY,
					variables: {
						featureName,
						username: urlVars.vvvv,
					},
					queryName: 'isFeatureEnabledQuery',
				}),
			}).then((res) => {
				return { [featureName]: res?.data?.isFeatureEnabled || false };
			});
			promises.push(promise);
		});

		const featureFlagData = promises.length && (await Promise.all(promises));

		featureFlagData?.forEach((flag) => {
			const key = Object.keys(flag)[0];
			const value = flag[key];
			allFlags[key] = value;
			if (value) {
				enabledFlags[key] = value;
			}
		});

		state.featureFlags = { ...activeFeatureFlags, ...enabledFlags };
		state.hasIsFeatureEnabledQueryData = true;

		return { state };

		// return allFlags;
	} catch (err) {
		return Promise.reject({
			state: { hasIsFeatureEnabledQueryFailed: true },
			errors: { IS_FEATURE_ENABLED_QUERY: err },
		});
	}
};

export default getFeatureFlagsQuery;
