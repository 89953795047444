import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { Bumps, CouponFields, OrderTotals, SubmitButton } from '..';
import { MediaQueryContext } from '@/state/MediaQueryContext';
import { useStore } from '@/state/stores';
import { cbNegative } from '@/theme';
import { Layouts } from '@/utils/enums';

const cartFooterCSS = css`
	.server-error {
		display: block;
		padding: 1rem;
		margin: 1.5rem 0;
		border: 1px solid ${cbNegative[280]};
		border-radius: 0.25rem;
		color: ${cbNegative[280]};
		background-color: ${cbNegative[950]};
	}
`;

const CartFooter = (props) => {
	const { formServerErrorInfo, orderTotals, showCoupon, template } = useStore(
		useShallow((state) => ({
			formServerErrorInfo: state.formServerErrorInfo,
			orderTotals: state.orderTotals,
			showCoupon: state.showCoupon,
			template: state.template,
		})),
	);
	const { bumpList, shouldDisplayBumps, bottomBorderColor } = props;
	const { t } = useTranslation('checkout');
	const { isSmallScreen } = useContext(MediaQueryContext);
	const errorRef = useRef();

	const showBumpsInCart =
		[Layouts.STANDARD, Layouts.JOINED].includes(template.layout) || isSmallScreen;

	const scrollToError = () => {
		errorRef.current.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	};

	useEffect(() => {
		formServerErrorInfo && scrollToError();
	}, [formServerErrorInfo]);

	const shouldShowSubmitButtonInCart =
		!isSmallScreen && [Layouts.STANDARD, Layouts.JOINED].includes(template.layout);

	return (
		<div css={cartFooterCSS} ref={errorRef}>
			<OrderTotals
				orderTotals={orderTotals && orderTotals[0]}
				bottomBorderColor={bottomBorderColor}
			/>
			{showCoupon && <CouponFields bottomBorderColor={bottomBorderColor} />}
			{shouldDisplayBumps && showBumpsInCart && <Bumps bumpList={bumpList} hideTopBorder />}
			{formServerErrorInfo ? (
				<span className="server-error">
					{t(
						`server-errors.${formServerErrorInfo.key}`,
						...(formServerErrorInfo.vars ? [formServerErrorInfo.vars] : []),
					)}
				</span>
			) : null}
			{/* Button is positioned fixed to bottom of viewport on mobile - is instead rendered in Checkout.js */}
			{shouldShowSubmitButtonInCart && <SubmitButton />}
		</div>
	);
};

CartFooter.propTypes = {
	bumpList: PropTypes.array,
	shouldDisplayBumps: PropTypes.bool,
	bottomBorderColor: PropTypes.string,
};

export default CartFooter;
