import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useState } from 'react';

import CountdownTimer from './CountdownTimer';
import { getVisibilityStyle } from '@/components/layoutFactory/layoutFactoryConstants';
import { useStore } from '@/state/stores';
import {
	breakpointMap,
	cbNeutral,
	cbPrimary,
	defaultBorderColor,
	fontWeightMedium,
	primaryFont,
} from '@/theme';
import { CountdownTimerActionType, WidgetVisibility } from '@/utils/enums';

export const CountdownTimerStyle = {
	STANDARD: 'standard',
	DIGITAL: 'digital',
	CIRCLE: 'circle',
	SQUARE: 'square',
};

const styles = (style, hideTimer, breakpointMap, zoneId, timeBoxWidth) => {
	const {
		backgroundColor,
		borderColor,
		borderRadius,
		borderSize,
		borderStyle,
		padding,
		timerDigitColor,
		timerDigitFontFamily,
		timerDigitFontSize,
		timerLabelColor,
		timerLabelDisplay,
		timerLabelFontFamily,
		timerLabelFontSize,
		timerDigitSpacing,
		timerStyle,
		topTextDisplay,
		bottomTextDisplay,
	} = style?.attributes || {};
	const bgColor = backgroundColor || 'transparent';

	const hasBorder = !!borderSize;
	const border = hasBorder
		? `${borderSize} ${borderStyle || 'solid'} ${borderColor || defaultBorderColor}`
		: 'none';

	const hasCircleDigitBorder = timerStyle === CountdownTimerStyle.CIRCLE;
	const isHeaderOrFooter = zoneId === 'header' || zoneId === 'footer';

	const hasTimeBoxWidth = timeBoxWidth !== 0;

	const fontFamily =
		timerStyle === CountdownTimerStyle.DIGITAL
			? CountdownTimerStyle.DIGITAL
			: timerDigitFontFamily || primaryFont;

	const getDigitFontSize = (view) => {
		if (!timerDigitFontSize) {
			return '30px';
		}
		if (view === 'DESKTOP') {
			return `clamp(12px, ${isHeaderOrFooter ? '7.5vw' : '3.5vw'}, ${timerDigitFontSize})`;
		}
		if (view === 'TABLET') {
			return `clamp(12px, ${isHeaderOrFooter ? '10vw' : '4vw'}, ${timerDigitFontSize})`;
		}
		if (view === 'MOBILE') {
			return `clamp(12px, 7vw, ${timerDigitFontSize})`;
		}
	};

	return css`
		// Text from CK editor is bringing unwanted margin along for the ride.  This removes it.
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p {
			margin: 0;
		}
		width: 100%;
		border: ${border};
		background: ${bgColor};
		padding: ${padding || '0 0 0 0'};
		box-sizing: border-box;
		border-radius: ${borderRadius || 0};

		& * {
			${hideTimer ? 'display: none !important' : ''}
		}

		.MuiTypography-root.timer {
			font-weight: ${fontWeightMedium};
			color: ${timerDigitColor || cbPrimary[180]};
			font-family: ${fontFamily};

			${`${breakpointMap.DESKTOP} {
				font-size: ${getDigitFontSize('DESKTOP')}
			}`}
			${`${breakpointMap.TABLET} {
				font-size: ${getDigitFontSize('TABLET')}
			}`}
			${`${breakpointMap.MOBILE} {
				font-size: ${getDigitFontSize('MOBILE')}
			}`}
		}

		.container {
			display: flex;
			justify-content: center;
			padding: 10px 0;
			// This is to prevent the timer from showing before the timeBoxWidth is calculated
			opacity: ${hasTimeBoxWidth ? '1' : '0'};
		}

		.MuiTypography-root.label {
			font-size: ${timerLabelFontSize ? `clamp(12px, 3.5vw, ${timerLabelFontSize})` : '14px'};
			color: ${timerLabelColor || cbNeutral[400]};
			display: ${timerLabelDisplay || 'block'};
			font-family: ${timerLabelFontFamily || primaryFont};
			font-weight: ${fontWeightMedium};

			${`${breakpointMap.TABLET} {
				font-size: ${timerDigitFontSize ? `clamp(12px, 2.5vw, ${timerLabelFontSize})` : '14px'}
			}`}
		}

		.time-descriptor-box {
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: ${timeBoxWidth}px;

			margin: 0 ${timerDigitSpacing || '2px'};
			&:first-of-type {
				margin-right: ${timerDigitSpacing || '2px'};
			}
			&:last-of-type {
				margin-left: ${timerDigitSpacing || '2px'};
			}

			.border {
				display: flex;
				align-items: center;
				justify-content: center;
				border: 2px solid ${timerDigitColor || cbPrimary[180]};
				border-radius: ${hasCircleDigitBorder ? '50%' : 'none'};
				padding: 14px;
				min-width: 20px;
				aspect-ratio: 1/1;
				max-width: 100px;
			}
		}

		.description {
			text-align: center;
			color: ${cbNeutral[550]};
			font-family: 'Source Sans Pro', sans-serif;
			font-size: 1rem;
		}

		.CB-countdown-timer-module {
			border: 1px solid red;
		}

		.topText {
			display: ${topTextDisplay === 'none' ? 'none' : ''};
		}

		.bottomText {
			display: ${bottomTextDisplay === 'none' ? 'none' : ''};
		}
	`;
};

const CountdownTimerWidget = ({
	style,
	expirationText,
	topText,
	bottomText,
	visibility,
	zoneId,
	id,
}) => {
	const template = useStore((state) => state.template);
	if (!template.countdownTimer) {
		return null;
	}
	const countdownTimerHasExpired = useStore((state) => state.countdownTimerHasExpired);
	// const [timeBoxWidth, setTimeBoxWidth] = useState(54.3); //54.3 is the size if use the default options in the OFC
	const [timeBoxWidth, setTimeBoxWidth] = useState(0); //54.3 is the size if use the default options in the OFC
	const { actionType } = template.countdownTimer;
	const { timerStyle } = style?.attributes || {};
	const showExpirationMessage =
		countdownTimerHasExpired && actionType === CountdownTimerActionType.NOT_AVAILABLE;
	const hideTimer =
		countdownTimerHasExpired && actionType === CountdownTimerActionType.HIDE_TIMER;

	return (
		<>
			<div
				css={[
					styles(style, hideTimer, breakpointMap, zoneId, timeBoxWidth),
					getVisibilityStyle(visibility),
				]}
				className="CB-countdown-timer-module"
			>
				{showExpirationMessage && (
					<div
						dangerouslySetInnerHTML={{ __html: expirationText }}
						className="CB-countdown-expiration-message"
					/>
				)}
				{!showExpirationMessage && topText && (
					<div dangerouslySetInnerHTML={{ __html: topText }} className="topText" />
				)}
				<CountdownTimer
					timerStyle={timerStyle}
					timerId={id}
					setTimeBoxWidth={setTimeBoxWidth}
				/>
				{!showExpirationMessage && bottomText && (
					<div dangerouslySetInnerHTML={{ __html: bottomText }} className="bottomText" />
				)}
			</div>
		</>
	);
};

export default CountdownTimerWidget;

CountdownTimerWidget.propTypes = {
	style: PropTypes.shape({
		attributes: PropTypes.shape({
			borderSize: PropTypes.string,
			borderStyle: PropTypes.string,
			borderColor: PropTypes.string,
			padding: PropTypes.string,
			borderRadius: PropTypes.string,
			timerDigitColor: PropTypes.string,
			timerDigitFontFamily: PropTypes.string,
			timerDigitFontSize: PropTypes.string,
			timerStyle: PropTypes.string,
			timerLabelColor: PropTypes.string,
			timerLabelFontSize: PropTypes.string,
			timerLabelFontFamily: PropTypes.string,
			timerLabelDisplay: PropTypes.string,
		}),
	}),
	topText: PropTypes.string,
	bottomText: PropTypes.string,
	expirationText: PropTypes.string,
	zoneId: PropTypes.string,
	id: PropTypes.string,
	visibility: PropTypes.arrayOf(
		PropTypes.oneOf([
			WidgetVisibility.MOBILE,
			WidgetVisibility.MOBILE_2,
			WidgetVisibility.TABLET,
			WidgetVisibility.TABLET_2,
			WidgetVisibility.DESKTOP,
			WidgetVisibility.DESKTOP_2,
		]),
	),
};
